import { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { api } from '../redux'
import { handleGetErrors } from '../redux/error/actions'

// { query: [{ key: 'id', op: '=', value: id }] }
export function useGetQuery (type, params, autoFetch = true) {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(undefined)
  const [data, setData] = useState(null)

  const effects = useCallback((params) => {
    setStatus('pending')
    if (type === 'project') {
      api.getProjects({ query: params }).then(result => {
        if (result.data.value && result.data.value.length > 0) {
          setData(result.data.value[0])
        }
        setStatus('fulfilled')
      }).catch(() => {
        handleGetErrors('project', dispatch)
        setStatus('rejected')
      })
    } else if (type === 'contact') {
      api.getContacts({ query: params }).then(result => {
        if (result.data.value && result.data.value.length > 0) {
          setData(result.data.value[0])
        }
        setStatus('fulfilled')
      }).catch(() => {
        handleGetErrors('project', dispatch)
        setStatus('rejected')
      })
    }
  }, [dispatch, setData, setStatus])

  useEffect(() => {
    if (type && params && autoFetch === true && status === undefined) {
      effects(params)
    }
  }, [effects, params, status, autoFetch])

  const refetch = useCallback((params) => {
    effects(params)
  }, [effects])

  const isLoading = status === 'pending'
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}
