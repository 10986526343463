import {
  RESET_SELECTED_ITEM_ACTION,
  SELECT_ITEM_ACTION
} from './actions.js'

const DEFAULT = {
  current: null
}

/**
 * Reducer for selected item in current tree view.
 */
export function selected (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case SELECT_ITEM_ACTION: {
      const { current } = state
      return Object.assign({}, state, {
        current: action.item,
        previous: current
      })
    }
    case RESET_SELECTED_ITEM_ACTION:
      return DEFAULT
    default:
      return state
  }
}
