import React, { Fragment, useMemo } from 'react'
import { getItemIcon } from '../util'
import { styled, List, Link, Waves } from '@pergas-common/pergas-components'
import { ArrowRight, ArrowDown, Open } from '@pergas-common/pergas-icons'
import { makeSelectorIsSearching } from '../redux/files/selectors'
import { useSelector } from 'react-redux'

const Item = styled(List.Item)`
  padding-left: ${({ offset }) => offset ? `${offset * 8}px` : '0'};
`

const Action = styled.span`
  margin-left: auto !important;
  padding-right: 16px;
  overflow: initial !important; 
`

const TreeView = ({
  items,
  onItemSelected,
  expand,
  isSelected
}) => {
  const selectIsSearching = useMemo(makeSelectorIsSearching, [])
  const isSearching = useSelector((state) => selectIsSearching(state))

  const renderItem = (item, level) => {
    const children = Array.isArray(item.children) ? item.children : []

    const renderArrow = () => {
      if (Array.isArray(item.children) && item.type !== 'rootfolder' && item.itemCount > 0) {
        if (expand[item.id]) {
          return <span style={{ paddingRight: '8px' }}><ArrowDown width={8} height={8} /></span>
        } else {
          return <span style={{ paddingRight: '8px' }}><ArrowRight width={8} height={8} /></span>
        }
      }
      return <span style={{ paddingRight: '16px' }} />
    }

    const renderedChildren = children.length > 0 && (expand[item.id] && renderItems(children, level + 1))
    return (
      <Fragment key={item.id}>
        <Item
          offset={level}
          onClick={(e) => onItemSelected(e, item)}
          selected={isSelected(item)}
        >
          {renderArrow()}
          {getItemIcon(item)}
          <span>{item.name}</span>
          {item.type === 'file' && (
            <Action>
              <Link onClickHandler={(e) => {
                onItemSelected(null, item)
              }}
              ><Open width={24} height={24} color='#28afe0' />
              </Link>
            </Action>
          )}
        </Item>
        {renderedChildren}
      </Fragment>
    )
  }

  const renderItems = (items, level) => items.map(item => renderItem(item, level))
  const result = isSearching ? <Item offest={0} onClick={() => {}}><Waves width={24} height={24} /></Item> : renderItems(items, 0)
  return (
    <List.Content>
      <List.Items>
        {result}
      </List.Items>
    </List.Content>
  )
}

export default TreeView
