/*

å = @a
Å = @@a
ä = @b
Ä = @@b
Ö = @c
ö=  @@c
é= @d
É = @@d
ó= @e
Ó =  @@e
á = @f
Á = @@f

*/

const mappings = [
  {
    Character: 'Á',
    'Windows-1252': '.@@f',
    'UTF-8': '%C3%81'
  },
  {
    Character: 'Ä',
    'Windows-1252': '.@@b',
    'UTF-8': '%C3%84'
  },
  {
    Character: 'Å',
    'Windows-1252': '.@@a',
    'UTF-8': '%C3%85'
  },
  {
    Character: 'É',
    'Windows-1252': '.@@d',
    'UTF-8': '%C3%89'
  },
  {
    Character: 'Ó',
    'Windows-1252': '.@@e',
    'UTF-8': '%C3%93'
  },
  {
    Character: 'Ö',
    'Windows-1252': '.@@c',
    'UTF-8': '%C3%96'
  },
  {
    Character: 'á',
    'Windows-1252': '.@f',
    'UTF-8': '%C3%A1'
  },
  {
    Character: 'ä',
    'Windows-1252': '.@b',
    'UTF-8': '%C3%A4'
  },
  {
    Character: 'å',
    'Windows-1252': '.@a',
    'UTF-8': '%C3%A5'
  },
  {
    Character: 'é',
    'Windows-1252': '.@d',
    'UTF-8': '%C3%A9'
  },
  {
    Character: 'ó',
    'Windows-1252': '.@e',
    'UTF-8': '%C3%B3'
  },
  {
    Character: 'ö',
    'Windows-1252': '.@c',
    'UTF-8': '%C3%B6'
  }
]

// mutates string
export function replace (str) {
  mappings.forEach((c) => {
    str = str.replace(new RegExp(c['UTF-8'], 'g'), c['Windows-1252'])
  })
  return str
}
