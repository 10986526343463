const config = Object.freeze({
  api: {
    baseUrl: process.env.REACT_APP_API_URL
  },
  msal: {
    auth: {
      clientId: 'c286cedd-de87-4c03-b7b4-a03bff1e75b0'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  },
  outlook: {
    readRetryCount: 30,
    readRetryTimeout: 2000
  },
  autoLoginTimeout: 300,
  errorTimeout: 7000,
  waitForSaveTimeout: 5000
})

export default config
