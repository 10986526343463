import api from '../api.js'
import store from '../store.js'
import msal from '../../lib/msal.js'
import { isValidToken } from '../../util.js'
import storage from '../storage.js'
import {
  ERROR_ACTION,
  resetError,
  unknownError,
  networkError
} from '../error/actions.js'
import { busy, notBusy } from '../busy/actions.js'
import { hideDialog } from '../dialog/actions.js'
import { beginProgressDialog } from '../progress/actions.js'

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION'
export const ERROR_TYPE_LOGIN = 'ERROR_TYPE_LOGIN'

/**
 * Different messages during failed login.
 */
export const ERROR_LOGIN_NO_ACCOUNT = 'ERROR_LOGIN_NO_ACCOUNT'
export const ERROR_LOGIN_ACCESS_TOKEN = 'ERROR_LOGIN_ACCESS_TOKEN'
export const ERROR_LOGIN_GRAPH_ACCESS_TOKEN = 'ERROR_LOGIN_GRAPH_ACCESS_TOKEN'

let heartBeatInterval = null

/**
 * Login heart beat function.
 */
export function doStartHeartbeat () {
  return function (dispatch) {
    if (heartBeatInterval) {
      clearInterval(heartBeatInterval)
      heartBeatInterval = null
    }

    function heartBeat () {
      const { userData } = store.getState().login
      const { token } = userData
      if (!isValidToken(token)) {
        storage.deleteApiToken()
        window.location.reload()
      }
    }

    heartBeatInterval = setInterval(heartBeat, 60 * 1000)
  }
}

/**
 * Pre request hook that sets api token for all requests
 */
api.setPreHook(async (req) => {
  const ud = store.getState().login.userData
  api.setHeader('Authorization', `Bearer ${ud.token}`)
})

/**
 * Get an api token from pds using idToken
 */
export function doLogin (idToken) {
  return function (dispatch) {
    dispatch(resetError())

    /**
     * Fetch a new api token.
     */
    dispatch(busy())
    api.login(idToken).then(async (result) => {
      dispatch(notBusy())
      const userData = result.data.value
      dispatch(loginSuccessful(userData))
    }).catch(err => {
      console.error('api.login() failed', err)
      dispatch(notBusy())
      const { response } = err
      if (response) {
        if (response.status === 401) {
          dispatch(loginError(ERROR_LOGIN_NO_ACCOUNT))
        } else {
          dispatch(unknownError(err))
        }
      } else {
        dispatch(networkError(err))
      }
    })
  }
}

function loginSuccessful (userData) {
  console.info('login successful!')
  setTimeout(() => {
    storage.putUserData(userData)
  }, 50)
  return {
    type: LOGIN_SUCCESS_ACTION,
    userData
  }
}

function loginError (message) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_LOGIN,
    errorMessage: message
  }
}

/**
 * Logs out the current user from Microsoft and clears cached data.
 */
export function doLogout () {
  return function (dispatch) {
    const { email } = store.getState().login.userData
    const account = msal.getAccountByUsername(email)
    dispatch(hideDialog())
    dispatch(
      beginProgressDialog(
        'PROGRESS_TITLE_LOGOUT',
        'PROGRESS_STATUS_LOGOUT'
      )
    )
    storage.deleteUserData()
    msal.logout({ account })
  }
}
