import { styled, Button, Group, Modal } from '@pergas-common/pergas-components'
import { Warning } from '@pergas-common/pergas-icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'

const Holder = styled.div`
  height: 50px;
  padding: 16px;
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
`

const Logout = ({ isOpen, onSubmit, onCancel }) => {
  const locale = useSelector(selectLocale)

  return (
    <Modal
      title={locale.logout} titleIcon={<Warning style={{ width: '20px', height: '20px' }} />} isOpen={isOpen} onCloseRequest={onCancel} size='md' footer={() => (
        <Group.Button>
          <Button onClick={onSubmit}>{locale.logout}</Button>
          <Button onClick={onCancel}>{locale.cancel}</Button>
        </Group.Button>
      )}
    >
      {() => (
        <Holder><span>{locale.logout_confirm}</span></Holder>
      )}
    </Modal>
  )
}

export default Logout
