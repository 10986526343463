import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { isValidToken } from './util.js'
import config from './config.js'
import redux from './redux/index.js'
import socket from './redux/socket-io.js'
import PdsSpinner from './components/PdsSpinner.js'
import NoAccount from './components/NoAccount.js'
import { Topbar, styled, Waves, Button } from '@pergas-common/pergas-components'
import FileView from './components/FileView.js'
import { Logo } from '@pergas-common/pergas-icons'
import { useGetQuery } from './api/index.js'
import { makeSelectorUserdata } from './redux/login/selectors.js'
import Logout from './components/Logout.js'

const ScrollStyle = {
  height: 'calc(100% - 64px)',
  overflow: 'scroll'
}

const Holder = styled.div`
  width: 100%;
`

const StyledButton = styled(Button)`
  background: white;
`

const App = ({
  idToken,
  strings,
  token,
  accessToken,
  doLogout,
  showNoAccountPage,
  connectWebsockets,
  doStartHeartbeat,
  doLogin
}) => {
  const [confirmLogout, setConfirmLogout] = useState(false)
  const isLoggedIn = isValidToken(token)

  // Start heartbeat on initial render.
  useEffect(doStartHeartbeat, [])

  // (Re-)Connect to websockets if token changed.
  useEffect(() => {
    if (isLoggedIn) {
      connectWebsockets(token)
    }
  }, [token])

  // Auto login if not logged in.
  useEffect(() => {
    if (!isLoggedIn) {
      setTimeout(() => doLogin(idToken), config.autoLoginTimeout)
    }
  }, [isLoggedIn])

  if (showNoAccountPage) {
    return <NoAccount />
  } else {
    return (
      <>
        {isLoggedIn
          ? (<>
            <div style={{ width: '100%', background: 'rgb(32, 106, 133)' }}>
              {confirmLogout && <Logout
                isOpen={confirmLogout} onSubmit={() => {
                  doLogout()
                  setConfirmLogout(false)
                }} onCancel={() => {
                  setConfirmLogout(false)
                }}
                                />}
              <Topbar
                left={<Logo width={32} height={32} fill='white' />} right={
                  <StyledButton
                    type='button' onClick={() => {
                      setConfirmLogout(true)
                    }}
                  >
                    {strings.logout}
                  </StyledButton>
}
              />
            </div>
            <div style={ScrollStyle}>
              <TmplViewer />
            </div>
          </> // eslint-disable-line
            )
          : <Spinner />}
      </>
    )
  }
}

const Spinner = () => {
  return (
    <div style={alignSpinnerStyle}>
      <PdsSpinner />
    </div>
  )
}

const queryParams = (params) => {
  if (!params || !params?.pds_externalid) {
    return null
  }
  return [{
    key: 'eid',
    op: '=',
    value: params?.pds_externalid
  }]
}

const TmplViewer = () => {
  const dispatch = useDispatch()
  const selectUserdata = useMemo(makeSelectorUserdata, [])
  const userData = useSelector((state) => selectUserdata(state))
  useEffect(() => {
    if (!userData.sc_root) {
      return
    }
    dispatch(redux.actions.files.getTemplatesRootFolder())
  }, [userData, dispatch])

  const urlParam = Object.fromEntries(new URLSearchParams(window.location.search).entries())

  // We only want to query for the resource if we have an external id to translate to a pergas id.
  const query = queryParams(urlParam)
  const { data, isLoading, isSuccessful } = useGetQuery(urlParam?.pds_type, query)

  if (isLoading) {
    return (
      <Holder>
        <div style={{ display: 'flex', justifyContent: 'center' }}><Waves width={24} height={24} /></div>
      </Holder>
    )
  }

  if (isSuccessful) {
    return (
      <Holder>
        <FileView pds_id={data?.id} {...urlParam} />
      </Holder>
    )
  }

  return (
    <Holder>
      {urlParam?.pds_id ? <FileView pds_id={urlParam.pds_id} {...urlParam} /> : <FileView {...urlParam} />}
    </Holder>
  )
}

const alignSpinnerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const mapStateToProps = (state) => {
  const { token } = state.login.userData
  return {
    error: state.error,
    strings: state.locale.strings,
    showNoAccountPage: state.login.showNoAccountPage,
    token
  }
}

const mapDispatchToProps = (dispatch) => {
  const { error, login } = redux.actions
  return {
    doLogin: (idToken) => {
      dispatch(login.doLogin(idToken))
    },
    doLogout: () => {
      dispatch(redux.actions.login.doLogout())
    },
    doStartHeartbeat: () => {
      dispatch(redux.actions.login.doStartHeartbeat())
    },
    connectWebsockets: (token) => {
      socket.connect(token, dispatch)
    },
    onResetError: () => {
      dispatch(error.resetError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
