/**
 * Get files and folders in a document library folder.
 *
 * siteUrl: url to site
 * path: path to file
 * spToken: sharepoint access token
 */
export async function getDocumentLibraryFiles ({ siteUrl, path }, spToken) {
  const url = `${siteUrl}/_api/Web/GetFolderByServerRelativeUrl('${path}')?$expand=Folders,Files`
  const res = await fetch(url, getRequestOptions(spToken))
  if (!res.ok) {
    throw new Error(`getDocumentLibraryFiles() failed (${res.status}) (${await res.text()})`)
  }
  return (await res.json()).d
}

/**
 * Search files in a document library.
 *
 * siteUrl: url to site
 * serverRelativeUrl: server relative url to list
 * query: search string
 * onResults: callback on results
 * onEnd: callback when finished
 * spToken: sharepoint access token
 */
export async function searchDocumentLibrary ({
  siteUrl,
  serverRelativeUrl,
  query,
  onResults,
  onEnd
}, spToken) {
  const opts = getRequestOptions(spToken)

  const GET = async (url) => {
    const res = await fetch(url, opts)
    if (!res.ok) {
      throw new Error(`partial search failed (${res.status}) (${await res.text()})`)
    }
    const data = await res.json()
    const { results } = data.d
    if (Array.isArray(results)) {
      onResults(results.map(f => f.File))
    }

    const nextUrl = data?.d?.__next
    if (typeof nextUrl === 'string') {
      await GET(nextUrl)
    } else {
      onEnd()
    }
  }

  const list = await getList(siteUrl, serverRelativeUrl, opts)
  const startUrl = `${siteUrl}/_api/Web/Lists/getById('${list.Id}')/items?$expand=File,FieldValuesAsText&$select=File,FieldValuesAsText&$filter=FSObjType eq '0' and substringof('${query}',FileLeafRef)`
  await GET(startUrl)
}

/**
 * Get a list by its relative url.
 *
 * siteUrl: url to site
 * serverRelativeUrl: server relative url to list
 * opts: auth options
 */
async function getList (siteUrl, listRelativeUrl, opts) {
  const select = '$select=Title,RootFolder/ServerRelativeUrl'
  const expand = '$expand=RootFolder'
  const filter = `$filter=RootFolder/ServerRelativeUrl eq '${listRelativeUrl}'`
  const url = `${siteUrl}/_api/web/lists?${select}&${expand}&${filter}`

  const res = await fetch(url, opts)
  if (!res.ok) {
    throw new Error(`selecting list failed (${res.status}) (${await res.text()})`)
  }

  const data = await res.json()
  if (Array.isArray(data.d?.results) && data.d.results.length > 0) {
    const listUri = data.d.results[0].__metadata.id
    const res = await fetch(listUri, opts)
    if (!res.ok) {
      throw new Error(`fetching list data failed (${res.status}) (${await res.text()})`)
    }
    return (await res.json()).d
  } else {
    throw new Error(`no relative url for site '${siteUrl}'`)
  }
}

/**
 * Returns request options needed to interact with the SharePoint REST api.
 */
function getRequestOptions (token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata=verbose',
      'Content-Type': 'application/json;odata=verbose'
    }
  }
}
