import API from './api.js'
import store from './store.js'

import * as error from './error/actions.js'
import * as files from './files/actions.js'
import * as locale from './locale/actions.js'
import * as login from './login/actions.js'
import * as progress from './progress/actions.js'
import * as selected from './selected/actions.js'

const redux = {
  store,
  actions: {
    error,
    files,
    locale,
    login,
    selected,
    progress
  }
}

export const api = API
export default redux
