import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Input } from '@pergas-common/pergas-components'
import { useDispatch, useSelector } from 'react-redux'
import redux from '../redux'
import { makeSelectorUserdata } from '../redux/login/selectors'
import { selectLocale } from '../redux/locale/selectors'

const useDebounce = (fn, delay) => {
  const timeout = useRef()
  const debouncedRequest = (query) => {
    timeout.current && clearTimeout(timeout.current)
    timeout.current = setTimeout(() => fn(query), delay)
  }

  useEffect(() => () => clearTimeout(timeout.current), [])
  return [debouncedRequest]
}

const { actions: { files } } = redux

const SearchInput = () => {
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()
  const [input, setInput] = useState('')

  const selectUserdata = useMemo(makeSelectorUserdata, [])
  const userData = useSelector((state) => selectUserdata(state))
  const onSearch = useCallback((inputValue) => {
    if (inputValue === '') {
      dispatch(files.getTemplatesRootFolder())
      dispatch(files.resetSearch())
      return
    }
    dispatch(files.searchFiles({ url: `${userData.sc_root}_admin` }, inputValue))
  }, [userData, dispatch])
  const [debouncedSearch] = useDebounce(onSearch, 200)

  const quickKeysEvent = useCallback((event) => {
    if (event.code === 'Escape' && input !== '') {
      setInput('')
      dispatch(files.getTemplatesRootFolder())
      dispatch(files.resetSearch())
    }
  }, [input, dispatch, setInput])

  useEffect(() => {
    document.addEventListener('keydown', quickKeysEvent)
    return () => {
      document.removeEventListener('keydown', quickKeysEvent)
    }
  }, [quickKeysEvent])

  return (
    <Input
      name='search' value={input} onChangeValue={(input) => {
        debouncedSearch(input.currentTarget.value)
        setInput(input.currentTarget.value)
      }} placeholder={`${locale.search_for_template}...`}
    />
  )
}

export default SearchInput
