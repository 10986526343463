import redux from './redux'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Modal from 'react-modal'
import { theme, ThemeProvider } from '@pergas-common/pergas-components'
import { Provider } from 'react-redux'
import './index.css'
import App from './App.js'
import msal from './lib/msal.js'
import storage from './redux/storage.js'
import { isValidToken } from './util.js'

Modal.setAppElement('#root-modal')

const start = ({ idToken = '', token = '' }) => {
  function Main () {
    return (
      <Provider store={redux.store}>
        <ThemeProvider theme={theme}>
          <App idToken={idToken} token={token} />
        </ThemeProvider>
      </Provider>
    )
  }
  createRoot(document.getElementById('root')).render(<Main />)
}

/**
 * Main login method to receive id token
 * 1. when the user hasn't logged in before, or
 * 2. when ssoSilent() fails
 */
function redirectLogin () {
  msal.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse !== null) {
      console.info('got id token from redirect method')
      start({ idToken: tokenResponse.idToken })
    } else {
      try {
        console.info('initiating redirect method')
        msal.loginRedirect()
      } catch (err) {
        // TODO this is a fatal error -> rende Error page with details
        console.error('msal.loginRedirect failed')
      }
    }
  }).catch((err) => {
    // TODO this is a fatal error -> rende Error page with details
    console.error('msal.handleRedirectPromise failed', err)
  })
}

const { token, email } = redux.store.getState().login.userData

if (isValidToken(token)) {
  console.info('found cached valid id token')
  start({ token })
} else {
  const loginHint = email
  if (typeof loginHint === 'string') {
    console.info('msal.ssoSilent() with login hint', loginHint)
    const silentRequest = { loginHint }
    msal.ssoSilent(silentRequest).then(result => {
      start({ idToken: result.idToken })
    }).catch((err) => {
      console.error('msal.ssoSilent failed', err)
      storage.deleteUserData()
      redirectLogin()
    })
  } else {
    redirectLogin()
  }
}
