import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorIsSearching = () => createSelector(
  (state) => state.files,
  (slice) => {
    const { isSearching } = slice
    return isSearching
  }
)

export const makeSelectorSearchResults = () => createSelector(
  (state) => state.files,
  (slice) => {
    const { searchResults } = slice
    return searchResults
  }
)
