import msal from '../../lib/msal.js'
import { getUserData } from '../common.js'
import store from '../store.js'
import { setSelectedItem } from '../selected/actions.js'
import {
  buildFileItemUrl,
  getItemUrl,
  getItemRoot
} from '../../util'
import {
  getDocumentLibraryFiles,
  searchDocumentLibrary
} from '../../lib/sharepoint/index.js'

import { ERROR_ACTION } from '../error/actions.js'

const ERROR_TYPE_DOC_LIB_GET = 'ERROR_TYPE_DOC_LIB_GET'
const ERROR_TYPE_FILE_SEARCH = 'ERROR_TYPE_FILE_SEARCH'

export const FILES_SET_SELECTED_FOLDER_ACTION = 'FILES_SET_SELECTED_FOLDER_ACTION'
export const FILES_SET_SELECTED_FILE_ACTION = 'FILES_SET_SELECTED_FILE_ACTION'
export const FILES_RESET_SELECTED_FILE_ACTION = 'FILES_RESET_SELECTED_FILE_ACTION'
export const FILES_GET_ROOT_FOLDER_ACTION = 'FILES_GET_ROOT_FOLDER_ACTION'
export const FILES_GET_SUB_FOLDER_ACTION = 'FILES_GET_SUB_FOLDER_ACTION'
export const FILES_SET_SEARCH_VALUE_ACTION = 'FILES_SET_SEARCH_VALUE_ACTION'
export const FILES_GOT_SEARCH_RESULTS_ACTION = 'FILES_GOT_SEARCH_RESULTS_ACTION'
export const FILES_BEGIN_SEARCH_ACTION = 'FILES_BEGIN_SEARCH_ACTION'
export const FILES_END_SEARCH_ACTION = 'FILES_END_SEARCH_ACTION'
export const FILES_RESET_SEARCH_ACTION = 'FILES_RESET_SEARCH_ACTION'

export function setSelectedFolder (folder) {
  return {
    type: FILES_SET_SELECTED_FOLDER_ACTION,
    folder
  }
}

export function setSelectedFile (file) {
  return {
    type: FILES_SET_SELECTED_FILE_ACTION,
    file
  }
}

export function resetSelectedFile () {
  return {
    type: FILES_RESET_SELECTED_FILE_ACTION
  }
}

export function setSearchValue (searchValue) {
  return {
    type: FILES_SET_SEARCH_VALUE_ACTION,
    searchValue
  }
}

export function resetSearch () {
  return {
    type: FILES_RESET_SEARCH_ACTION
  }
}

/**
 * Get files and folders from templates
 */
export function getTemplatesRootFolder () {
  const userData = getUserData(store)
  const state = store.getState()
  const item = {
    type: 'templates',
    id: 0,
    url: `${userData.sc_root}_admin`,
    name: state.locale.strings.templates_root_node
  }

  return function (dispatch) {
    dispatch(setSelectedItem(item))
    dispatch(getRootFolder(item))
  }
}

/**
 * Get files and folders for a root folder item
 */
export function getRootFolder (siteItem) {
  return async function (dispatch) {
    const url = getItemUrl(siteItem)
    const path = getItemRoot(siteItem)
    try {
      dispatch({
        type: FILES_GET_ROOT_FOLDER_ACTION,
        siteItem,
        path,
        items: await getFilesHelper(url, path)
      })
    } catch (err) {
      dispatch(documentLibraryError(err))
    }
  }
}

/**
 * Get files and folders from a sub folder
 */
export function getSubFolder (item, folderItem) {
  return async function (dispatch) {
    const url = getItemUrl(item)
    const { path } = folderItem
    try {
      dispatch({
        type: FILES_GET_SUB_FOLDER_ACTION,
        item,
        parent: folderItem,
        items: await getFilesHelper(url, path)
      })
    } catch (err) {
      dispatch(documentLibraryError(err))
    }
  }
}

async function getFilesHelper (siteUrl, path, cb) {
  const userData = getUserData(store)
  const params = { siteUrl, path }
  const accessToken = await getSpAccessToken(userData)
  const result = await getDocumentLibraryFiles(params, accessToken)

  const folders = result.Folders.results.filter(folder => {
    return folder.Name !== 'Forms'
  }).map(folder => {
    return {
      type: 'folder',
      children: [],
      id: folder.UniqueId,
      name: folder.Name,
      title: folder.Title,
      itemCount: folder.ItemCount,
      created: folder.TimeCreated,
      modified: folder.TimeLastModified,
      path: `${path}/${folder.Name}`
    }
  }).sort(compareFilenames)

  const files = result.Files.results.filter(file => {
    const length = parseInt(file.Length, 10)
    return !isNaN(length) && length > 0
  }).map(file => {
    return {
      type: 'file',
      id: file.UniqueId,
      name: file.Name,
      title: file.Title,
      created: file.TimeCreated,
      modified: file.TimeLastModified,
      path,
      uri: buildFileItemUrl(userData.sc_root, file),
      serverRelativeUrl: file.ServerRelativeUrl
    }
  }).sort(compareFilenames)

  return folders.concat(files)
}

/**
 * Search files in currently selected context.
 */
export function searchFiles (siteItem, query) {
  return async function (dispatch) {
    const userData = getUserData(store)
    const scRoot = userData.sc_root

    const siteUrl = getItemUrl(siteItem)
    const serverRelativeUrl = `${(new URL(siteItem.url)).pathname}/pdsMallar`
    console.info('searching files in', { siteItem, siteUrl, serverRelativeUrl, query })

    dispatch({ type: FILES_BEGIN_SEARCH_ACTION })

    function onResults (results) {
      results = results.filter(file => {
        const length = parseInt(file.Length, 10)
        return !isNaN(length) && length > 0
      }).map(file => {
        return {
          type: 'file',
          id: file.UniqueId,
          name: file.Name,
          title: file.Title,
          created: file.TimeCreated,
          modified: file.TimeLastModified,
          uri: buildFileItemUrl(scRoot, file),
          serverRelativeUrl: file.ServerRelativeUrl
        }
      })
      dispatch({ type: FILES_GOT_SEARCH_RESULTS_ACTION, results })
    }

    function onEnd () {
      dispatch({ type: FILES_END_SEARCH_ACTION })
    }

    try {
      const accessToken = await getSpAccessToken(userData)
      await searchDocumentLibrary({
        siteUrl,
        serverRelativeUrl,
        query,
        onResults,
        onEnd
      }, accessToken)
    } catch (err) {
      return dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_FILE_SEARCH,
        errorMessage: err.message
      })
    }
  }
}

async function getSpAccessToken (userData) {
  const url = new URL(userData.sc_root)
  const request = {
    scopes: [`${url.protocol}//${url.host}//.default`],
    account: msal.getAccountByUsername(userData.email)
  }
  try {
    const result = await msal.acquireTokenSilent(request)
    return result.accessToken
  } catch (err) {
    console.error('Failed to get access token', err)
    console.info('Trying acquireTokenRedirect')
    await msal.acquireTokenRedirect(request)
  }
}

function documentLibraryError (err) {
  console.error('documentLibraryerror', err)
  // TODO if error code is 401, then reload page
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_DOC_LIB_GET,
    errorMessage: err.message
  }
}

function compareFilenames (_lhs, _rhs) {
  const lhs = _lhs.name.toLowerCase()
  const rhs = _rhs.name.toLowerCase()
  if (lhs < rhs) return -1
  if (lhs === rhs) return 0
  if (lhs > rhs) return 1
}
